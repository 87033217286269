import { Review } from "services/review/review.service";
import { PartialDeep } from "type-fest";
import {
  ReviewClientEmail,
  ReviewClientEmailPartialById,
} from "services/review/models/review-client-email";
import {
  ReviewClientCall,
  ReviewClientCallPartialById,
} from "services/review/models/review-client-call.model";
import {
  ReviewServiceOrder,
  ReviewServiceOrderPartialById,
} from "services/models/review-service-order.model";
import {
  ReviewWebLink,
  ReviewWebLinkPartialById,
} from "services/review/models/review-web-link.model";
import { reviewService } from "services";
import { ReviewQuestions } from "services/review/models/review-questions.model";
import { object } from "prop-types";

export type UpdateReviewPayload = {
  id: string;
  review: PartialDeep<Review>;
  relationsToSave?: {
    reviewClientCalls?: ReviewClientCallPartialById[];
    reviewClientEmails?: ReviewClientEmailPartialById[];
    reviewServiceOrders?: ReviewServiceOrderPartialById[];
    reviewWebLinks?: ReviewWebLinkPartialById[];
    reviewQuestions?:ReviewQuestions[];
  };
  relationsToDelete?: {
    reviewClientCalls?: ReviewClientCall[];
    reviewClientEmails?: ReviewClientEmail[];
    reviewServiceOrders?: ReviewServiceOrder[];
    reviewWebLinks?: ReviewWebLink[];
  };
  
  
  
};

type Question = {
  id: string;
  name?: string;
  answerId: string;
  };
  

const updateReview = async (payload: UpdateReviewPayload) => {
  const reviewToUpdate: PartialDeep<Review> = { ...payload.review };
  //console.log("reviewToUpdate",reviewToUpdate)
  reviewToUpdate.rate = reviewToUpdate.rate === 0 ? null : reviewToUpdate.rate;
  reviewToUpdate.reviewChatMessages = undefined;
  reviewToUpdate.reviewStatuses = undefined;
  reviewToUpdate.reviewClientCalls = undefined;
  reviewToUpdate.reviewClientEmails = undefined;
  reviewToUpdate.reviewServiceOrders = undefined;
  reviewToUpdate.reviewWebLinks = undefined;
    //console.log('pauLoad',payload)
  const deleteTasks: Promise<any>[] = [];

  if (payload.relationsToDelete) {
    if (payload.relationsToDelete.reviewClientCalls) {
      payload.relationsToDelete.reviewClientCalls.forEach((reviewClientCall) =>
        deleteTasks.push(reviewService.deleteOneReviewClientCall(payload.id, reviewClientCall.id))
      );
    }
    if (payload.relationsToDelete.reviewClientEmails) {
      payload.relationsToDelete.reviewClientEmails.forEach((reviewClientEmail) =>
        deleteTasks.push(reviewService.deleteOneReviewClientEmail(payload.id, reviewClientEmail.id))
      );
    }
    if (payload.relationsToDelete.reviewServiceOrders) {
      payload.relationsToDelete.reviewServiceOrders.forEach((reviewServiceOrder) =>
        deleteTasks.push(
          reviewService.deleteOneReviewServiceOrder(payload.id, reviewServiceOrder.id)
        )
      );
    }
    if (payload.relationsToDelete.reviewWebLinks) {
      payload.relationsToDelete.reviewWebLinks.forEach((reviewWebLink) =>
        deleteTasks.push(reviewService.deleteOneReviewWebLink(payload.id, reviewWebLink.id))
      );
    }
  }

  await Promise.all(deleteTasks);

  const createTasks: Promise<any>[] = [];

  if (payload.relationsToSave) {
    //console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaa")
    if (
      payload.relationsToSave.reviewClientCalls &&
      payload.relationsToSave.reviewClientCalls.length > 0
    ) {
      createTasks.push(
        reviewService.createManyReviewClientCalls(
          payload.id,
          payload.relationsToSave.reviewClientCalls
        )
      );
    }
    if (
      payload.relationsToSave.reviewClientEmails &&
      payload.relationsToSave.reviewClientEmails.length > 0
    ) {
      createTasks.push(
        reviewService.createManyReviewClientEmails(
          payload.id,
          payload.relationsToSave.reviewClientEmails
        )
      );
    }
    if (
      payload.relationsToSave.reviewServiceOrders &&
      payload.relationsToSave.reviewServiceOrders.length > 0
    ) {
      createTasks.push(
        reviewService.createManyReviewServiceOrders(
          payload.id,
          payload.relationsToSave.reviewServiceOrders.map((reviewServiceOrder) => {
            reviewServiceOrder.serviceOrder = undefined;
            return reviewServiceOrder;
          })
        )
      );
    }
    if (
      payload.relationsToSave.reviewWebLinks &&
      payload.relationsToSave.reviewWebLinks.length > 0
    ) {
      createTasks.push(
        reviewService.createManyReviewWebLinks(
          payload.id,
          payload.relationsToSave.reviewWebLinks.map((reviewWebLinks) => {
            reviewWebLinks.reviewSite = undefined;
            return reviewWebLinks;
          })
        )
      );
    }
    if (
      payload.review.reviewQuestions &&
      payload.review.reviewQuestions.length > 0
    ) {
      
      let rrr:any[];
      rrr=payload.review.reviewQuestions
     // //console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaa",rrr)
      createTasks.push(
        reviewService.setReviewQuestions(
          payload.id,
          rrr.map((reviewQuestions) => {
            reviewQuestions.reviewQuestions = undefined;
            return reviewQuestions;
          })
        )
      );
    }


  }


    



  await Promise.all(createTasks);

  const setTasks: Promise<any>[] = [];

  if (reviewToUpdate) {
    if (reviewToUpdate.reviewTags) {
    //  //console.log("ReviewTagsss",reviewToUpdate)
      const reviewTagTypeIds: string[] = reviewToUpdate.reviewTags
        .map((reviewTag) => reviewTag?.reviewTagTypeId)
        .reduce((prev, curr) => {
          if (curr) {
            prev.push(curr);
          }
          return prev;
        }, [] as string[]);

      setTasks.push(reviewService.setReviewTags(payload.id, reviewTagTypeIds));
    }

    if (reviewToUpdate.reviewDestinations) {
      const reviewDestinationTypeIds: string[] = reviewToUpdate.reviewDestinations
        .map((reviewDestination) => reviewDestination?.reviewDestinationTypeId)
        .reduce((prev, curr) => {
          if (curr) {
            prev.push(curr);
          }
          return prev;
        }, [] as string[]);

      setTasks.push(reviewService.setReviewDestinations(payload.id, reviewDestinationTypeIds));
    }
  }


  if (reviewToUpdate) {
    if (reviewToUpdate.reviewQuestions) {
     // //console.log("FFFFFFFFFFFFFF",reviewToUpdate.reviewQuestions)
      const reviewQuestion: ReviewQuestions[] = reviewToUpdate.reviewQuestions
        .map((reviewQuestion) => {
          return {
            ...reviewQuestion,
            id:reviewQuestion?.id,
            name:reviewQuestion?.currentIssues?.name,
            answerId:reviewQuestion?.answerId 

          } as ReviewQuestions
        }) 
        

      setTasks.push(reviewService.setReviewQuestionsUpdate(payload.id, reviewQuestion));
    }
  }
  await Promise.all(setTasks);

  reviewToUpdate.reviewTags = undefined;
  reviewToUpdate.reviewDestinations = undefined;
  reviewToUpdate.reviewQuestions=undefined;
  return reviewService.updateOne(payload.id, reviewToUpdate);
};

export default updateReview;

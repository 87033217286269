import { QuerySort, QuerySortArr, CreateQueryParams } from "@nestjsx/crud-request";
import { Review } from "services/review/review.service";
import { PagiantedResponse } from "services/interfaces/paginated-response.interface";
import { ReviewStatusEnum } from "libs/enums/review-status.enum";
import { reviewService } from "services";

const fetchReviews = async () => {
  const validSort: Array<QuerySort | QuerySortArr> = [];

//   if (Array.isArray(params.sort)) {
//     for (const sortUnit of params.sort) {
//       if (Array.isArray(sortUnit) || typeof sortUnit !== "string") {
//         validSort.push(sortUnit);
//       } else {
//         break;
//       }
//     }

//     if (validSort.length === 0) {
//       validSort.push(params.sort as QuerySortArr);
//     }
//   } else if (params.sort) {
//     validSort.push(params.sort);
//   }

  validSort.push({ field: "id", order: "DESC" });



  const reviews = await reviewService.getReviewQuestions();

  //console.log('ffffff',reviews)

  return reviews;
};

export default fetchReviews;
